<template>
  <div class="dealers">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter" style="z-index: 11">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div class="inputs">
        <input
          type="text"
          v-model="searchValue"
          placeholder="输入合作商电话或姓名"
          @blur="blurIn"
        />
        <img src="../../assets/image/search.svg" alt @click="handleSearch" />
      </div>
    </div>
    <!-- 分类 -->
    <div class="classify">
      <div class="classify_left">
        <div ref="selectDate">全部</div>
        <img src="../../assets/image/bottom.svg" alt @click="showDate = true" />
      </div>
      <div class="classify_center">
        累计{{ zong_order_num ? zong_order_num : 0 }}笔，共{{
          zong_order_price ? zong_order_price : 0
        }}积分
      </div>
      <!-- <div class="classify_right" @click="handleToDetail">分发明细</div> -->
    </div>
    <!-- 内容 -->
    <div class="item" v-for="item in list" :key="item.user_id">
      <div class="item_top">
        <img :src="item.head_pic" alt />
        <div class="item_top_center">
          <div class="center_top">
            <span class="center_top_title">{{ item.nickname }}</span>
            <span class="center_top_num">团队ID:{{ item.team_num }}</span>
          </div>
          <div class="center_bottom">
            <span>累计订单：</span>
            <span class="center_bottom_num"
              >{{ item.order_num }}笔，共{{ item.order_price }}积分</span
            >
          </div>
        </div>
        <div>
          <!-- <div class="item_top_right" @click="handleIntegral(item.user_id)">分发积分</div>
          <div class="item_top_right mt8" @click="handlenext(item.user_id)">下级合伙人</div> -->
        </div>
      </div>
      <div class="item_bottom">
        <div class="item_bottom_left">
          <div class="item_bottom_left_top">
            <span>初级等级：</span>
            <span class="level">{{ item.dl_name_cs }}</span>
          </div>
          <div>
            <span>联系电话：</span>
            <span class="phone">{{ item.mobile }}</span>
          </div>
        </div>
        <div class="item_bottom_right">
          <div class="item_bottom_left_top">
            <span>当前等级：</span>
            <span class="level">{{ item.dl_name_now }}</span>
          </div>
          <div>
            <span>来源：</span>
            <span class="from">{{ item.source }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="none" v-show="list instanceof Array && list.length == 0">
      暂无列表
    </div>
    <!-- 日期弹框 -->
    <van-popup
      v-model="showDate"
      position="bottom"
      :style="{ height: '250px' }"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        title="标题"
        :columns="columns"
        @change="onChange"
        swipe-duration
        item-height="50"
        visible-item-count="5"
      />
    </van-popup>
    <!-- 分发积分弹框 -->
    <van-overlay
      :show="showIntegral"
      @click="showIntegral = false"
      :lock-scroll="false"
    >
      <div class="exchange_popup">
        <div class="popup_img">
          <img
            src="../../assets/image/close.svg"
            alt
            @click="showIntegral = false"
          />
        </div>
        <div class="popup_content" @click.stop>
          <div class="popup_title">请输入分发积分</div>
          <div class="number_input">
            <input v-model="numbers" placeholder="输入内容" @blur="blurIn" />
          </div>
          <div class="btn btn_w" @click="handleConfirm">确认</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchValue: "",
      show: false,
      showIntegral: false,
      list: [],
      columns: [],
      showDate: false,
      numbers: "",
      zong_order_num: "",
      zong_order_price: "",
      uid: "",
    };
  },
  computed: {
    scrollHeight() {
      return document.documentElement.scrollTop || document.body.scrollTop || 0;
    },
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    onChange(picker, value, index) {
      this.$refs.selectDate.innerHTML = value;
      console.log(picker, value, index);
      this.showDate = false;
      this.handleGetDealersList(value);
    },
    async handleConfirm() {
      const data = await this.$api.Give({
        uid: this.uid,
        money: this.numbers,
      });
      if (data.data.code === 1) {
        this.$notify({ type: "success", message: data.data.msg });
        this.numbers = "";
        this.handleGetDealersList();
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
        this.numbers = "";
      }
      console.log(data, "data");
      this.showIntegral = false;
    },
    handleIntegral(id) {
      this.uid = id;
      this.showIntegral = true;
    },
    handleToDetail() {
      this.$router.push({
        path: "/distribution/index",
      });
    },
    handleSearch() {
      this.handleGetDealersList();
    },
    async handleGetLevelList() {
      const data = await this.$api.getLevelList();
      if (data.data.code === 1) {
        let list = ["全部"];
        this.columns = list.concat(data.data.data);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetDealersList(value) {
      const data = await this.$api.subordinates({
        dl_name_now: value ? value : this.$refs.selectDate.innerHTML,
        keyword: this.searchValue,
        user_id: this.uid,
      });
      if (data.data.code === 1) {
        this.list = data.data.data.userlist;
        this.zong_order_num = data.data.data.zong_order_num;
        this.zong_order_price = data.data.data.zong_order_price;
        document.title = data.data.data.subordinates_name + "的经销商";
      } else {
        this.list = [];
        this.zong_order_num = "";
        this.zong_order_price = "";
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    blurIn() {
      window.scrollTo(0, Math.max(this.scrollHeight - 1, 0));
    },
    handlenext(id) {
      console.log(id);
      //   this.$router.push({path:'/dealers/detail',query:{id}})
    },
  },
  mounted() {
    this.uid = this.$route.query.id;
    this.handleGetLevelList();
    this.handleGetDealersList();
  },
};
</script>
<style lang="scss" scoped>
.dealers {
  width: 375px;
  min-height: 100vh;
  background: rgba(245, 245, 245, 1);
  padding-top: 40px;
  box-sizing: border-box;
  //顶部bar
  .top_bar {
    width: 375px;
    height: 40px;
    background: #0e3592;
    position: fixed;
    top: 0;
    font-size: 15px;
    color: black;
    line-height: 40px;
    text-align: center;
    z-index: 1;
    .top_bar_icon {
      position: absolute;
      left: 11px;
      width: 16px;
      height: 16px;
      color: #7e7e7e;
      top: 4px;
    }
  }
  .inputs {
    position: relative;
    input {
      border: none;
      width: 290px;
      height: 30px;
      line-height: 30px;
      // background: rgba(255, 255, 255, 1);
      border-radius: 15px;
      outline: none;
      -webkit-appearance: none; /*去除系统默认的样式*/
      border: 1px solid #d2d2d2;
      padding-left: 47px;
      padding-right: 30px;
      font-size: 13px;
    }
    input::-webkit-input-placeholder {
      color: #999;
      font-size: 13px;
    }
    img {
      width: 12px;
      height: 14px;
      position: absolute;
      top: 14px;
      right: 53px;
    }
  }
  .classify {
    width: 100%;
    height: 45px;
    padding-left: 12px;
    padding-right: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .classify_left {
      width: 80px;
      height: 25px;
      background: #0e3592;
      border-radius: 13px;
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        width: 10px;
        height: 10px;
      }
    }
    .classify_center {
      font-size: 13px;
      color: rgba(51, 51, 51, 1);
      line-height: 13px;
      margin-left: -15px;
    }
    .classify_right {
      font-size: 12px;
      color: rgba(0, 177, 0, 1);
      line-height: 12px;
      // margin-top: 19px;
    }
  }
  .item {
    width: 375px;
    height: 162px;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    font-size: 12px;
    .item_top {
      width: 351px;
      height: 85px;
      margin: 0 auto;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      //   justify-content: space-between;
      padding-top: 20px;
      box-sizing: border-box;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .item_top_center {
        margin-left: 20px;
        .center_top {
          .center_top_title {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 15px;
          }
          .center_top_num {
            font-size: 11px;
            color: rgba(153, 153, 153, 1);
            line-height: 11px;
            margin-left: 15px;
          }
        }
        .center_bottom {
          font-size: 12px;
          color: rgba(102, 102, 102, 1);
          line-height: 12px;
          margin-top: 22px;
          .center_bottom_num {
            color: rgba(250, 46, 46, 1);
            margin-left: 5px;
          }
        }
      }
      .item_top_right {
        width: 70px;
        height: 25px;
        border-radius: 13px;
        border: 1px solid rgba(0, 177, 0, 1);
        font-size: 12px;
        color: rgba(0, 177, 0, 1);
        line-height: 25px;
        text-align: center;
      }
    }
    .item_bottom {
      width: 351px;
      height: 76px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      color: rgba(102, 102, 102, 1);
      align-items: center;
      .item_bottom_left,
      .item_bottom_right {
        .item_bottom_left_top {
          margin-bottom: 21px;
          .level {
            color: rgba(51, 51, 51, 1);
          }
        }
        .from {
          color: rgba(0, 177, 0, 1);
        }
        .phone {
          color: rgba(50, 125, 251, 1);
        }
      }
    }
  }
  .none {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #999;
    margin-top: 100px;
  }
  .exchange_popup {
    position: relative;
    .popup_img {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 104px;
      right: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .popup_content {
      width: 310px;
      height: 210px;
      background: rgba(255, 255, 255, 1);
      border-radius: 6px;
      position: absolute;
      top: 136px;
      right: 32px;
      padding-top: 20px;
      box-sizing: border-box;
      .popup_title {
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
      .number_input {
        width: 210px;
        height: 40px;
        margin-top: 30px;
        margin-left: 50px;
        input {
          -webkit-appearance: none; /*去除系统默认的样式*/
          //           -moz-user-select: none;
          // -webkit-user-select: none;
          width: 210px;
          height: 40px;
          border-radius: 25px;
          border: none;
          border: 1px solid rgba(193, 193, 193, 1);
          padding: 0 10px;
          font-size: 16px;
          box-sizing: border-box;
          // color: rgba(153, 153, 153, 1);
          line-height: 22px;
          background: rgba(247, 247, 247, 1);
          text-align: center;
        }
        input::-webkit-input-placeholder {
          color: #999;
          font-size: 13px;
        }
      }
      .btn_w {
        position: absolute;
        width: 210px;
        bottom: 30px;
        left: 50px;
      }
    }
  }
  .mt8 {
    margin-top: 6px;
  }
}
</style>
